import React from "react"
import Layout from "../components/layout"

import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import WebriQForm from "@webriq/gatsby-webriq-form"
// import ContactUsBg from "../images/new-images/contact-us-bg.jpg"

import { LazyLoadComponent } from "react-lazy-load-image-component"
import "./contact.css"

import Testimonials2 from "../components/swiper/testimonial-swiper"

const Banner = () => {
  //   const { heroImage } = useStaticQuery(
  //     graphql`
  //       {
  //         heroImage: file(relativePath: { eq: "new-images/contact-us-bg.jpg" }) {
  //           childImageSharp {
  //             gatsbyImageData(
  //               width: 1920
  //               layout: CONSTRAINED
  //               formats: [AUTO, WEBP, AVIF]
  //             )
  //           }
  //         }
  //       }
  //     `
  //   )

  //   const heroImageData = getImage(heroImage)

  //   // Use like this:
  //   const bgImage = convertToBgImage(heroImageData)

  return (
    // <div
    //   className="hero-banner contact-banner"
    //   style={{
    //     background: `url(${ContactUsBg}) bottom no-repeat`,
    //     backgroundBlendMode: "overlay",
    //   }}
    // >
    <div className="hero-banner relative pt-4 overflow-hidden">
      <div className="hero-overlay relative">
        <StaticImage
          placeholder="none"
          className="wave-img"
          src="https://cdn.sanity.io/images/55lcecww/production/5ef5b5149a6edf0ad2c67cbe3874446d1cee06e2-904x650.png"
          alt="System Integration"
          layout="constrained"
          style={{ height: "fit-content", zIndex: 0 }}
          fetchpriority="low"
        />
      </div>
      <div className="container relative my-auto" style={{ zIndex: 1 }}>
        <div className="row pb-3 align-items-center">
          <div className="col-lg-7 col-xl-7 col-md-7">
            <div
              className="hero-custom-content-home review-content text-white"
              style={{ padding: "20px 0", marginTop: "5.3rem" }}
            >
              <h1
                className="page-title text-left"
                style={{
                  marginBottom: "0.5rem",
                  display: "inline",
                }}
              >
                {/* {item?.title} */}
                Contact Us
              </h1>
              <p className="mb-0 mt-2 text-left">
                {/* {item.description} */}
                Speak with one of our experts to learn how your team can make
                significant operational improvements with AI, rapid innovation,
                and software asset management.
              </p>
              {/* {item?.url && ( */}
              <div className="hero-button">
                <Link
                  // to={`${item?.url}`}
                  to="/contact"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                  style={{ marginTop: "auto" }}
                >
                  {/* {item?.label} */}
                  LET'S CHAT
                </Link>
              </div>
              {/* )} */}
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 pb-2 d-flex justify-content-end"
            style={{ position: "relative", marginTop: "2rem" }}
          >
            <StaticImage
              placeholder="none"
              className="rounded d-flex w-100"
              src="https://cdn.sanity.io/images/55lcecww/production/9c00ec3048ee7bf6456b82f554b69b7c8850e552-1800x1800.png"
              alt="System Integration"
              layout="constrained"
              style={{ height: "fit-content", zIndex: 1 }}
              fetchpriority="low"
            />
            {/* <DesignElement />
          <DesignElement2 /> */}
          </div>
        </div>
      </div>
      <div className="swiperOverlay"></div>
    </div>
  )
}

class ContactPage extends React.Component {
  render() {
    // const { data } = this.props
    // const teams = data.allSanityTeam.

    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Contact Us | Quandary Consulting Group"
          description="Speak with one of our experts to learn how your team can make significant operational improvements with AI, rapid innovation, and software asset management."
        />

        <section
          id="speak-with-specialist"
          style={{ paddingTop: "80px", paddingBottom: "80px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-7 m-auto form-wrap-contact">
                <div className="form-wrapper">
                  <div className="intro mb-2">
                    <div className="row">
                      <div className="col-md-12">
                        <h2 className="mt-0 font-weight-medium">
                          Speak with Our Innovation Team
                        </h2>
                        <p>
                          Use the form below to drop us an e-mail. We'll be in
                          touch shortly.
                        </p>
                        <small className="text-red">
                          Fields marked with an asterisk (*) are required.
                        </small>
                      </div>
                    </div>
                  </div>
                  <WebriQForm
                    id="contact-form"
                    name="Contact Form"
                    className="contactForm"
                    data-form-id="4d46fe65-4d66-46b4-946c-e5826349ed3c"
                    data-thankyou-url="/thank-you"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        {/* <p class="required">Full Name</p> */}

                        <input
                          type="text"
                          name="Full Name"
                          size={40}
                          className="form-control required"
                          placeholder="Full Name *"
                          aria-label="Full Name"
                          required
                          id="requiredField"
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 mt-4 mt-lg-0">
                        {/* <p class="required">Phone</p> */}
                        <input
                          type="text"
                          name="Phone"
                          size={40}
                          // minLength={14}
                          className="form-control required"
                          id="phone"
                          placeholder="Phone Number *"
                          aria-label="Phone"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        {/* <p class="required">Email</p> */}
                        <input
                          type="email"
                          name="Email"
                          size={40}
                          className="form-control"
                          aria-label="Email"
                          placeholder="Email *"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        {/* <p class="required">Message</p> */}
                        <textarea
                          name="Message"
                          rows={5}
                          cols={0}
                          className="form-control"
                          aria-label="Message"
                          placeholder="Message *"
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        {/* <p class="required">How did you find out about us?</p> */}
                        <div>
                          <textarea
                            type="text"
                            name="How did you find out about us?"
                            size={40}
                            rows={5}
                            className="form-control"
                            aria-label="How did you find out about us?"
                            placeholder="How did you find out about us? *"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="form-group col-12 d-flex justify-content-start captcha-wrap">
                        <div className="webriq-recaptcha" />
                      </div>
                      <div className="form-group col-12 text-left">
                        <div className="input-filled">
                          <button
                            type="submit"
                            className="btn btn-primary btn-arrow"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </WebriQForm>
                </div>
              </div>
              <div className="col-lg-5 mt-4 mt-lg-0">
                <div className="intro mb-2">
                  <p className="h5 text-light-blue text-uppercase font-weight-bold mt-0">
                    Where You Can Find Us
                  </p>
                </div>
                <ul className="list-unstyled contact-info">
                  <li className="mb-3">
                    <i
                      className="fa fa-map-marker fa-lg mr-4"
                      aria-hidden="true"
                    />
                    <span>
                      6400 S Fiddlers Green Circle - Suite 250 <br />
                      Greenwood Village, CO 80111
                    </span>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-mobile fa-2x mr-4" aria-hidden="true" />
                    <a
                      aria-label="link"
                      href="tel:+17207391406"
                      onclick="ga('send', 'event', 'Phone Call Tracking', 'Click to Call', '720-739-1406', 0);"
                    >
                      (720) 739-1406
                    </a>
                  </li>
                  <li className="mb-3">
                    <i className="fa fa-envelope mr-4" aria-hidden="true" />
                    <a aria-label="link" href="mailto:info@quandarycg.com">
                      info@quandarycg.com
                    </a>
                  </li>
                  <li className="mb-3">
                    <i
                      className="fa fa-linkedin mr-4"
                      style={{ fontSize: "1.2rem" }}
                    />
                    <a
                      href="https://www.linkedin.com/company/quandarycg"
                      ref="noreferrer noopener"
                    >
                      quandarycg
                    </a>
                  </li>
                </ul>
                <div className="contact-map mt-4">
                  <iframe
                    title="map"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3074.2109991522548!2d-104.8919906!3d39.5999291!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c878152b57ac3%3A0x19ed7cd94c8a6747!2s6400%20S%20Fiddlers%20Green%20Cir%2C%20Greenwood%20Village%2C%20CO%2080111%2C%20USA!5e0!3m2!1sen!2sph!4v1696398667775!5m2!1sen!2sph"
                    // src="https://maps.google.com/maps?q=4980%20Harlan%20Street%20Denver,%20Colorado%2080212&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    width="100%"
                    height={335}
                    frameBorder={0}
                    style={{ border: "0px", pointerEvents: "none" }}
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <LazyLoadComponent>
          <div className="text-center intro bg-gradient">
            <Testimonials2 />
          </div>
        </LazyLoadComponent>
      </Layout>
    )
  }
}

export default ContactPage
